import * as React from "react";
import {
  cardContainer,
  cardContentContainer,
  cardHeader,
  cardShadow,
  agencyLinkStyle,
} from "./AgencyCard.module.scss";
import { cardColor } from "../../utilities/colors";
import { GatsbyImage } from "gatsby-plugin-image";

/**
 * Returns the agencycard component
 *
 * Data is passed from the JSON file that's loaded for the page.
 *
 * Since some pages have a banner on these cards with a specific type + color
 * we're passing in a prop on which card we'd like to create. We then set the background color
 * to whichever type we've passed in.
 *
 *
 */

export default function AgencyCard({
  type,
  agencyName,
  agencyLogo,
  nps,
  agencyLink,
  enterprise,
}) {
  const backgroundColor = cardColor(type);

  //agencyLogo and agencyLink can be added in later when we know where the data is being sourced from
  return (
    <div className={cardContainer}>
      <div className={cardShadow}>
        {!enterprise && (
          <div style={{ backgroundColor: backgroundColor }}>
            <h5 className={cardHeader}>{`Top ${type}`}</h5>
          </div>
        )}
        <div className={cardContentContainer}>
          <div style={{ marginBottom: "1.5rem" }}>
            <GatsbyImage image={agencyLogo} alt={`${agencyName} logo`} />
          </div>
          <h5>{agencyName}</h5>
          <h4>{`NPS: ${nps.toFixed(2)}`}</h4>
          <a href={agencyLink} className={agencyLinkStyle}>
            View Agency Profile
          </a>
        </div>
      </div>
    </div>
  );
}
