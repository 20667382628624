import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { cardColor } from "../../utilities/colors";
import { capitalizeFirstLetter } from "../../utilities/capitalize";
import {
  leaderboardSectionContainer,
  leaderboardContainer,
  leaderboard,
  leaderboardHeader,
  leaderboardGrid,
  leaderboardCells,
  contentScroll,
  shadowRadius,
  badgePill,
} from "./Leaderboard.module.scss";

/**
 * Returns the Leadeboard section / grid.
 *
 * Currently this is setup as a CSS grid and is not a table or flex row/columns.
 *
 * Dummy data is being passed down to a map function (see line 23 and 29).
 *
 * Realistically we can store this either in a JSON file or in Strapi depending on how
 * we want this data to come in. If we want this to be static and only update on build then
 * we can store in Strapi and update as needed. If we wanted this to dynamically update I believe
 * we can still use standard useEffect -> fetchAPI.
 *
 * But this would allow marketing etc. to update, change, add or remove agencies from that list.
 */

export default function Leaderboard() {
  //grabs data from agencies and ranks them in desc order based on NPS entered. Excludes enterprise agencies.

  const data = useStaticQuery(graphql`
    query leaderboardRanking {
      allStrapiAgencies(
        sort: { fields: info___nps, order: DESC }
        filter: { enterprise: { id: { eq: null } } }
      ) {
        nodes {
          id
          Agency
          enterprise {
            Name
          }
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
          }
        }
      }
    }
  `);

  const agencies = data.allStrapiAgencies.nodes;

  return (
    <div id="leaderboard" className={leaderboardSectionContainer}>
      <h2>Top agencies</h2>
      <div className={`${contentScroll} ${shadowRadius}`}>
        <div className={leaderboardContainer}>
          <div className={`${leaderboardHeader} ${leaderboardGrid}`}>
            <div>Rank</div>
            <div style={{ textAlign: "left" }}>Agency name</div>
            <div>NPS</div>
            <div>Agency type</div>
          </div>
          <div className={leaderboard}>
            {agencies.map((agency, i) => {
              return (
                <a
                  href={`agencies/${agency.slug}`}
                  key={`${agency.id}`}
                  className={`${leaderboardCells} ${leaderboardGrid}`}
                >
                  <div>{i + 1}</div>
                  <div style={{ textAlign: "left" }}>{agency.info.name}</div>
                  <div>{agency.info.nps.toFixed(2)}</div>
                  <div
                    style={{
                      width: "10rem",
                      padding: "0 1.25rem",
                    }}
                  >
                    <h5
                      style={{ backgroundColor: cardColor(agency.info.type) }}
                      className={badgePill}
                    >
                      {agency.info.type &&
                        capitalizeFirstLetter(agency.info.type)}
                    </h5>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
