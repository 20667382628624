import * as React from "react";
import {
  heroContainer,
  illustration,
  imageContainer,
  h1Container,
  h1,
  heroCtaContainer,
  indiumH1Container,
  indiumH1,
  indiumImageContainer,
  indiumLogo,
} from "./Hero.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import HeroCard from "../HeroCard/HeroCard";
import HeroCta from "../HeroCta/HeroCta";

/**
 * Returns a 2x2 grid containing the hero section
 *
 * We pass an enterprise flag to this component to readjust the layout slightly for indium
 * It's specifically labelled enterprise in the event that we create another partner page we
 * can easily accomodate.
 *
 * Currently the styling is hardcoded to be conditional for Indium, but that is a
 * TODO: to make more scalable for partners pages.
 */

export default function Hero({
  heroHeader,
  heroTextContent,
  enterprise,
  enterpriseName,
}) {
  /*Can also store this in an external file and import the object in. Image may need to be grabbed via GatsbyImage query
   *It may make more sense to remove this as a component and create the index.js Hero within the page and then create an enterprise hero component if the styling remains consistent throughout enterprises.
   */

  const enterpriseStyles = {
    Indium: {
      h1Container: indiumH1Container,
      h1: indiumH1,
      imageContainer: indiumImageContainer,
      logo: {
        imageStyle: indiumLogo,
        logoPath: "../../images/indium_logo.png",
        alt: "Indium logo",
      },
    },
  };

  const enterpriseStyle = enterpriseStyles[enterpriseName];

  return (
    <div className={heroContainer}>
      <div className={enterprise ? enterpriseStyle.h1Container : h1Container}>
        <div>
          <h1 className={enterprise ? enterpriseStyle.h1 : h1}>{heroHeader}</h1>
        </div>
      </div>
      {enterprise ? (
        <div className={enterpriseStyle.imageContainer}>
          <StaticImage
            src="../../images/indium_logo.png"
            alt={enterpriseStyle.logo.alt}
            placeholder="none"
            className={enterpriseStyle.logo.imageStyle}
          />
        </div>
      ) : (
        <div className={imageContainer}>
          <StaticImage
            src="../../images/illustration.svg"
            alt="Woman standing on mountain top waving flag"
            placeholder="none"
            className={illustration}
          />
        </div>
      )}
      <div></div>
      <section className={heroCtaContainer}>
        <HeroCard heroTextContent={heroTextContent} />
        <HeroCta />
      </section>
    </div>
  );
}
