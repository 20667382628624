// extracted by mini-css-extract-plugin
export var heroContainer = "Hero-module--hero-container--1KZGk";
export var h1Container = "Hero-module--h1-container--1yff7";
export var h1 = "Hero-module--h1--2zm6x";
export var imageContainer = "Hero-module--image-container--3HiMq";
export var illustration = "Hero-module--illustration--3q5x_";
export var heroCtaContainer = "Hero-module--hero-cta-container--3g5VU";
export var indiumH1Container = "Hero-module--indium-h1-container--2DABT";
export var indiumImageContainer = "Hero-module--indium-image-container--1p57q";
export var indiumLogo = "Hero-module--indiumLogo--2pvEH";
export var indiumH1 = "Hero-module--indium-h1--1Dcr0";