import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CtaButton from "../CtaButton/CtaButton";
import {
  heroCtaContainer,
  ctaContent,
  outerCtaContainer,
} from "./HeroCta.module.scss";

/**
 * Hero CTA is its own component in the event we wanted to change copy or modify the CTA
 * we have in our Hero section. This belongs to the overall grid that is created within the Hero.js component.
 *
 * It returns two divs with the image icons + CTA buttons that are fed the text that they should present.
 *
 *
 *
 */

export default function HeroCta() {
  return (
    <div className={heroCtaContainer}>
      <div className={outerCtaContainer}>
        <div className={ctaContent}>
          <div>
            <StaticImage
              src="../../images/icon_2.svg"
              alt="icon of leaderboard"
              width={80}
              height={80}
              placeholder="none"
            />
          </div>
          <h5>View the top agencies.</h5>
          <CtaButton name="View the leaderboard" link="/#leaderboard" />
        </div>
        <div className={ctaContent}>
          <div>
            <StaticImage
              src="../../images/icon_1.svg"
              alt="picture of magnifying glass"
              width={80}
              height={80}
              placeholder="none"
            />
          </div>
          <h5>See how you rank.</h5>
          <CtaButton name="Compare my score" link="/find-your-rank" />
        </div>
      </div>
    </div>
  );
}
