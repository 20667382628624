import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../Layouts/Layout/Layout";
import Seo from "../components/Seo/Seo";
import Hero from "../components/Hero/Hero";
import AgencyHighlights from "../components/AgencyHighlights/AgencyHighlights";
import RocketReferralsCta from "../components/RocketReferralsCta/RocketReferralsCta";
import Leaderboard from "../components/Leaderboard/Leaderboard";

import { whiteBackground } from "../styles/landing.module.scss";
import {
  darkSky,
  sky,
  cloud,
  mountainRight,
  mountainLeft,
} from "../styles/design.module.scss";

/**
 * Homepage
 *
 * We run a static query on the respective homepage in strapi/graphql to get the content
 * specifically for that page. In this case it's only the 'hero card' component.
 *
 * We can only run one static query per page or per component. However, we can run multiple
 * queries within that one staticquery. So, if we needed to query the resources collection and
 * the agency list collection (we do this on the indium page) we can combine our queries.
 *
 *
 * Anything to do with the macro layout and css is done here as well as our wrapper is instigated here
 * to contain the content. The rest of the styling lives within the specific components/section's css modules.
 *
 *
 * CSS modules are being used here because they work well with Gatsby/React style components and allow us to not worry
 * about conflicts with class names and reduces complexity of a large CSS file and messiness of 'styled components'.
 */

const IndexPage = () => {
  const getHomeContent = useStaticQuery(graphql`
    query homepage {
      strapiPages(name: { eq: "home" }) {
        hero_header
        hero {
          Title
          Description
        }
      }
    }
  `);

  const { hero_header, hero } = getHomeContent.strapiPages;

  return (
    <Layout>
      <Seo title="Home" />
      <div
        style={{
          position: "relative",
          marginTop: "-4.5rem",
          paddingTop: "4.5rem",
        }}
      >
        <div
          className={sky}
          style={{
            bottom: "200px",
          }}
        >
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <section className="wrapper">
          <Hero heroHeader={hero_header} heroTextContent={hero} />
        </section>
      </div>
      <section className="wrapper">
        <AgencyHighlights />
      </section>
      <div style={{ position: "relative" }}>
        <div className={sky}>
          <div
            className="wrapper"
            style={{ position: "relative", zIndex: "1500", height: "100%" }}
          >
            <StaticImage
              className={mountainLeft}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../images/mountain_left.svg"
              alt=""
            />
            <StaticImage
              className={mountainRight}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../images/mountain_right.svg"
              alt=""
            />
          </div>

          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <RocketReferralsCta
          h2="We can make NPS work for you."
          buttonText="Check out Rocket Referrals"
          link="https://www.rocketreferrals.com/features"
        />
      </div>
      <div className={whiteBackground}>
        <section className="wrapper">
          <Leaderboard />
        </section>
      </div>
      <div style={{ position: "relative" }}>
        <div className={`${sky} ${darkSky}`} style={{ top: "-600px" }}>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <section className="wrapper">
          <RocketReferralsCta
            h2="Learn how to get your promoters working for you!"
            buttonText="Read our guide"
            link="/tips/promoters"
          />
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
