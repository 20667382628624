import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { agencyHighlightsContainer } from "./AgencyHighlights.module.scss";
import AgencyCard from "../AgencyCard/AgencyCard";

/**
 * Returns the agency highlights section which contains a .map of the AgencyCard component
 * We also use the enterprise flag here to determine if we should say "Enterprise's Top Agencies".
 * Which can be passed the name of the enterprise.
 *
 */

export default function AgencyHighlights({ enterprise, enterpriseName }) {
  const data = useStaticQuery(graphql`
    query topRank {
      personal: allStrapiAgencies(
        sort: { fields: info___nps, order: DESC }
        filter: { info: { type: { eq: "personal" } } }
      ) {
        nodes {
          id
          Agency
          enterprise {
            Name
          }
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 96)
                }
              }
            }
          }
        }
      }
      commercial: allStrapiAgencies(
        sort: { fields: info___nps, order: DESC }
        filter: { info: { type: { eq: "commercial" } } }
      ) {
        nodes {
          id
          Agency
          enterprise {
            Name
          }
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 96)
                }
              }
            }
          }
        }
      }
      new: allStrapiAgencies(
        sort: { fields: info___nps, order: DESC }
        filter: { info: { type: { eq: "new" } } }
      ) {
        nodes {
          id
          Agency
          enterprise {
            Name
          }
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 96)
                }
              }
            }
          }
        }
      }
      indium: allStrapiAgencies(
        sort: { fields: info___nps, order: DESC }
        filter: { info: {}, enterprise: { Name: { eq: "Indium" } } }
      ) {
        nodes {
          id
          Agency
          enterprise {
            Name
          }
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 96)
                }
              }
            }
          }
        }
      }
    }
  `);

  const agencies = [];

  if (!enterpriseName) {
    agencies.push(
      data.commercial.nodes[0],
      data.personal.nodes[0],
      data.new.nodes[0]
    );
  }

  if (enterpriseName === "Indium") {
    const [...items] = data.indium.nodes.slice(0, 2);
    agencies.push(...items);
  }

  return (
    <section className={agencyHighlightsContainer}>
      <h2>{` ${enterprise ? `${enterpriseName}'s top` : "Top"} agencies`}</h2>
      <div style={{ display: "flex", flexFlow: "row wrap", width: "100%" }}>
        {agencies.map((agency) => {
          return (
            <AgencyCard
              key={`${agency.info.name}-${agency.info.id}`}
              type={agency.info.type ? agency.info.type : ""}
              agencyName={agency.info.name}
              agencyLogo={getImage(agency.info?.logo?.localFile)}
              agencyLink={`/agencies/${agency.slug}`}
              nps={agency.info.nps}
              enterprise={enterprise}
            />
          );
        })}
      </div>
    </section>
  );
}
