import * as React from "react";
import { heroTextContainer } from "./HeroCard.module.scss";

/**
 * Returns the text in the first copy section within the Hero.js grid
 *
 * This is sourced from strapi via the staticquery ran on whichever page (currently: home, indium)
 */

export default function HeroCard({ heroTextContent }) {
  return (
    <div>
      <div className={heroTextContainer}>
        <h3>{heroTextContent.Title}</h3>
        <p>{heroTextContent.Description}</p>
      </div>
    </div>
  );
}
